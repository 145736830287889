p {
    @apply font-bold text-base text-lightGray mb-4
}

h2 {
    @apply text-md font-bold text-black mb-8 mt-16
}

h3 {
    @apply text-mdsm font-bold text-black mb-8 mt-12
}

span {
    @apply text-black
}

table {
    @apply w-full border-collapse mb-8
}

td, th {
    border: 1px solid #dddddd;
    @apply text-left p-4
}
